

















import { Component, Prop, Vue } from "vue-property-decorator";
import { AxiosError } from "axios";
import { DefaConfirmationResend } from "@defa-as/components";
import { findRouteByName } from "@defa-as/utils";
import { httpClient } from "@/http";
import { ROUTE_NAMES, routes } from "@/router";

@Component({
  components: {
    DefaConfirmationResend,
  },
})
export default class LoginError extends Vue {
  @Prop({ required: true }) readonly error!: AxiosError;
  @Prop({ default: "" }) readonly email!: string;

  get httpClient() {
    return httpClient;
  }

  get confirmationPath() {
    return findRouteByName(routes, ROUTE_NAMES.REGISTRATION.CONFIRMATION).path;
  }

  get isAuthenticationError() {
    return (
      this.error.response?.status === 401 || this.error.response?.status === 404
    );
  }

  get isAuthorizationError() {
    return (
      this.error.response?.status === 403 &&
      this.error.response?.data?.error?.details?.reason !== "NOT_ACTIVATED"
    );
  }

  get isActivationError() {
    return (
      this.error.response?.status === 403 &&
      this.error.response?.data?.error?.details?.reason === "NOT_ACTIVATED"
    );
  }
}
