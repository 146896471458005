





































import { Component, Vue } from "vue-property-decorator";
import { DefaBaseButton, DefaLoaderBar } from "@defa-as/components";
import { userModule } from "@/store/modules/user-module";
import UsernameInput from "@/components/inputs/UsernameInput.vue";
import PasswordInput from "@/components/inputs/PasswordInput.vue";
import { ROUTE_NAMES } from "@/router";
import { AxiosError } from "axios";
import LoginError from "@/components/login/LoginError.vue";
import { Nullable } from "@defa-as/utils";

const initialError = () => ({
  value: null as Nullable<AxiosError>,
  email: "",
});

@Component({
  components: {
    LoginError,
    PasswordInput,
    UsernameInput,
    DefaLoaderBar,
    DefaBaseButton,
  },
})
export default class ViewLogin extends Vue {
  model = {
    email: "",
    password: "",
  };
  error = initialError();
  loading = false;

  get recoverPasswordLink() {
    return {
      name: ROUTE_NAMES.PASSWORD_RECOVERY.INITIAL,
    };
  }

  get registrationLink() {
    return {
      name: ROUTE_NAMES.REGISTRATION.INITIAL,
    };
  }

  get userModule() {
    return userModule;
  }

  async login() {
    this.loading = true;
    try {
      await this.userModule.login({
        email: this.model.email.trim(),
        password: this.model.password,
      });
      const redirectTo = this.$route.query["redirect-to"];
      if (typeof redirectTo === "string") {
        return await this.$router.replace(redirectTo);
      }
      return await this.$router.replace({
        name: ROUTE_NAMES.INSTALLATION.LIST,
      });
    } catch (error) {
      this.error.value = error;
      this.error.email = this.model.email;
    } finally {
      this.loading = false;
    }
  }
}
